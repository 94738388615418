.box-label-premiere {
      width: 894px;  /*177.8,  // 7, // 6.88" */
      height: 560px;  /* 101.6,  // 4" */
      border: solid 1px green;
      background-color: white;;
      position: relative;

}
.box-label-premiere  .voided{
      position:absolute;
      right: 50%;
      top: 42%;
      font-size: 5rem !important;
}
.box-label-premiere .left-color-bar{

      width: 19%;
      display: flex;
      flex-direction: column;
      height: 100%;
      position: relative;
}

.box-label-premiere .left-color-bar .qr-code {
      display: flex;
      flex-basis: fit-content;
}
.box-label-premiere .left-color-bar .qr-code svg {
      width: 100%;
      padding: 10px;
      height: fit-content;
}
.box-label-premiere .left-color-bar .bar-text, .box-label-premiere .left-color-bar .serial {
      font-size: 1.8rem;
      writing-mode: vertical-rl;
      transform: rotate(180deg);
      white-space: nowrap;
      display: inline-block;
      overflow: visible;
      text-align: center;
      height: 100%;
      z-index: 44;
}
.box-label-premiere .left-color-bar .serial {
      position: absolute;
      top: 0;
      right: -33px;
      text-align: end;
      margin-top: 50%;
      font-size: 1.3rem;
      color: #aaaaac;
}
.box-label-premiere .right-column{
      position: relative;
}
.box-label-premiere .open-box-overlay{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      text-align: center;
      justify-content: center;
      font-size: 14rem;
      color: red;
      line-height: 13rem;
      font-weight: 600;
      opacity: 0.3;
      margin-left: 25px;
      margin-top: 22px;
}

.box-label-premiere .right-column .top-row{
      height: 80%;
}

.box-label-premiere .right-column .top-row .frame-image{
      background-color: white;
      width: 50%;
}
.box-label-premiere .right-column .top-row .frame-description{
      background-color: white;
      width: 50%;
}
.box-label-premiere .right-column .top-row .frame-description .warm-silver{
      background-color: #a0a0a3;
      color: white;
      font-size: .8rem;
      padding: 2px;
      width: fit-content;
}
.box-label-premiere .right-column .top-row .frame-description .frame-description-lines{
      margin-right: 1.2rem;
}

.box-label-premiere .right-column .top-row .frame-description .frame-style{
      font-size: 2.5rem;
      font-weight: 700;
      align-items: center;
      justify-content: space-between;
      max-width: 241px;
}
.box-label-premiere .right-column .top-row .frame-description .frame-style .frame-size-in-corner{
 
      color: white;
      font-size: 3rem;
      text-align: right;
      width: 100%;
      margin: 12%;
      font-weight: 500;
}


.box-label-premiere .footer-bar{
      background-color: white;
      font-size: 1.4rem;
      color: #c6a67d;
      height: 20%;
      width: 100%;
      font-weight: 700;
}
.box-label-premiere .footer-bar.decoslim{

      font-size: 1rem;
      font-weight: 700;
}

.box-label-premiere .footer-bar .bottom-bar{
      border-top: 3px solid #c6a67d;
      width: 95%;
      align-self: center;
      justify-self: center;
}
.frame-description.flex.flex-column {
    font-size: 1.3rem;
    font-weight: 500;
}

.box-label-premiere .triangle {
      width: 186px;
      aspect-ratio: 1;
      clip-path: polygon(0 0, 100% 100%, 100% 0);
      align-self: flex-start;
      color: white;
      display: flex;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 33;
}

.decoslim-collection-logo{
      margin-left: 5%;
      width: auto;
      height: 59px;
      align-self: center;
}

.box-label-premiere.target  .frame-profile-name {
      font-size: 2rem;
} 
.box-label-premiere.target  .frame-style-name {
      font-size: 5rem;
} 
.box-label-premiere.target  .frame-image img {
      height: auto;
      padding-left: 25px;
} 

.box-label-premiere.target .right-column .top-row .frame-description{
      background-color: white;
      width: 50%;
      justify-content: end;;
}