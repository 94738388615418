.user-list-wrapper {
    border: 1px solid lightgray;
    padding: 10px;
    background-color: white;
    border-radius: 5px;
}

.user-list-wrapper .user-entry {
      border: 1px solid lightgray;
      padding: 10px 15px;
      background-color: #e8e8e8;
      color: black;
      border-radius: 10px;
      margin: 5px;
}

.user-list-wrapper .user-entry.excluded-user {
      background-color: rgb(200, 127, 138);
}