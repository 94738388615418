.body {
      width: 100%; /* calc(100% - 5rem);*/
     /* margin-left: 5rem; */
      z-index: 0;
      position: relative;
      top: 0;
      transition: all 0.5s ease;
      background: #f4f7fa;
      padding: 3rem;
    }
    
    .body-trimmed {
      width: calc(100% - 16.5625rem);
      margin-left: 16.5625rem; /*push body to the right so the menu shows */
    }
    
    .body-md-screen {
      width: calc(100% - 5rem);
      margin-left: 5rem;
    }