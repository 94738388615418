
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.frame {
      display: flex;
      flex-direction: row;
      margin: 5px 10px 0px 10px;
      border: 1px solid gray;
      background-color: white;
}
.frame-year-border{
      width: 20px;
      margin-right: 5px;
}

.year2020{
      background-color: red;
}

.year2021{
      background-color: green;
}

.frame-image {
      margin: 10px;
      background-color: purple;
}
.frame-image .frame-style-color img {
      width: 100px;
      padding: 5px 5px 2px 25px;
  }
.frame-details{
      display:flex;
      flex-direction: column;
      margin-left: 10px;
      padding-top: 10px;
 }

 .frame-details .top-row {
      display: flex;
      flex-direction: row;
}

.frame-details .top-row .age{
      color: red;
      width: 80px;
      margin-left: 20px;
}

.frame-details .other-properties {
      color: green
}

.time-entries{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
}

.time-entries .entry {
      display:flex;
      margin-right: 5px;
}

.frame-completion{
      display:flex;
}

.title-gray {
      font-weight: bolder;
      color: darkgray;
}

.station-header{
      display: flex;
      align-items: flex-start;
      flex-direction: column;
}
.station-header .entry{
      display: flex;
      margin-right: 10px;
}

.station-header .user-name {
      margin-right: 10px;
}
.station-header .summary {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
  }

.user-section {
    text-align: left;
    padding: 10px;
    background-color: #1a2a46;
    border-radius: 5px 28px 0 0;
    margin-top: 10px;
}

.grid-header-2021{
      background-color:green;
      color:white;
}

.grid-header-2020{
      background-color:red;
      color:white;
}

.report-title-wrapper {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 30px;
      align-items: baseline;
}
.report-title-wrapper .report-title {
      font-size: 2rem;
      font-weight: 600;
      color: slategray;
}
.report-title-wrapper .report-date-title{
      font-size: 1.25rem;
      color: gray;
      margin-left: 1rem;
}

.report-header2 {
      background-color: #1a2a46;
    display: flex;
    color: white;
    padding: 10px;
}
nav {

      background-color:#33393f;
}

nav ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
	width: 200px;
	overflow: hidden;
	background-color:#33393f;
}

nav li a {
	display: block;
	color: white;
	text-align: left;
	padding: 16px;
	font-family: "Arial Black";
	text-decoration: none;
}

nav li a:hover {
	background-color: #8fb6dc;

}

.calendar-selector{
      display: flex;
      background-color:#1f2162;
      padding: 5px;
}

.eod-header-row {
      background-color: #f8f9fa;
      padding: 10px;
}


.eod-header-row .row {
      width: 130px;
      text-align: end;
      font-weight: 600;
      border-bottom: 1px solid lightgray;
      padding: 10px 0 10px 0;
}
.eod-header-row :first-child {
      text-align: start !important;
      width: 200px;
}

.eod-header-row{
      display: flex;
}

.eod-table-row{
      display: flex;
}


.eod-table-row .row {
      width: 130px;
      text-align: end;
      border-bottom: 1px solid lightgray;
      padding: 10px 0;
}

.eod-table-row :first-child {
      font-weight: bold;
      width: 200px;
      text-align: start !important;
}

thead.p-datatable-thead tr th {
      background-color: #1a2a46 !important;
      color: white !important;
  }

  span.p-column-title {
      font-weight: 300;
  }
  
  .ag-left-aligned-cell{
      text-align: left;;
  }

  .alloy-header{
      display:flex;
      font-size: 30px;
      color: white;
      background: #1a2a46;
      padding: 5px;
  }
  .total-col {
      text-align: right;;
  }

  .consumed-cell{
      color: red;
  }

  .received-cell{
      color: green;
  }

  .frame-consumption-entry{
    
       align-items: flex-start;
       border: 1px solid gray;
       align-items: center !important;
  }

  .frame-consumption-entry-image{
      margin-right:10px;      
 }


 .frame-consumption-mapping{
      max-width:200px;
      display:flex;
      align-items: center;
      border: 1px solid gray;
      padding: 1px;
      margin-right: 10px;
      background-color: white;;
 }
 .frame-consumption-mapping img {
      max-width: 85px;
      object-fit: fill;
 }
 .frame-consumption-part-mapping-list {
      background-color: #cdcdcd;
      display: inherit;
      padding: 5px;
      flex-wrap: wrap;
 }

 .frame-cell-framestyle{
      min-width:300px;
      border-bottom: 1px solid gray;
      display: flex;
      align-items: center;
      padding-left: 10px;
      color: black;
      font-weight: 600;
  }
.frame-cell-framestyle .year {

}
.frame-header-cell {
      
      color: white;
}
 
 .frame-grid-cell {
      width: 50px;
      padding:10px;
      margin-right:2px;
      border: 1px solid gray;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2px;
 }
 .frame-grid-cell.year {

      border: none !important;
      height: 20px
 }
 .frame-grid-cell.size{ 
      color:white;
 }

.frame-grid-cell.empty {
      color: transparent;
      background-color: darkgray;
}
.frame-grid-cell.total {
      background-color: black;
      color: white;
}

.frame-grid-station-title{

      font-variant: all-small-caps;
      font-size: 34px;
      width: 100%;
      background-color: #1a2a46;
      color: white;
      text-align: left;
      padding: 10px;
}
.station-productivity-report{
      margin-bottom: 20px;
}

.body-panel > .p-toggleable-content > .p-panel-content {
      background:#eaeaea !important; 
}

.body-panel > .p-toggleable-content > .p-panel-content > .p-card {
      margin-bottom: 20px;
}
.nested-card.p-card.p-component {
      margin-bottom: 20px;
  }


  @media (max-width: 1138px) {
      .side-bar {
            display: flex;
            position: absolute;
            z-index: 3;
      }
      .menu-button {
            display: flex
      }
    }
.p-menu{
      background-color: #010710;
}

.p-menuitem-text {
      color: #eeeeee;
}

.p-submenu-header {
      color: darkslategray
}
.p-submenu-header {
      color: #fafafa;
      background-color: #3a3a3a;
      text-align: left;
  }

  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
      color: #d4d6da;
      background: #1a2a46;
  }
  
  .p-menuitem .p-focus{
      color: #d4d6da;
      background: #1a2a46;
  }


.size-32 {
      background-color: orange;
  }

.size-43 {
      background-color: #f579c9;
  }

.size-50 {
      background-color: #f5f568;
  }

.size-55 {
      background-color: #661a97;
  }

.size-65 {
      background-color: #5b8fdd;
  }

.size-75 {
      background-color: #526b7c;
  }
.size-85 {
      background-color: #196239;
  }

  .range-radio-options label {
      color:white;
  }