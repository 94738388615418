.sdsdf{
      background: #2b2b2c;
}
.sidenav {
      background-color:#2c2c2c;
      transition: all 0.5s ease;
      position: fixed;
      z-index: 1;
      top: 0;
      width: 16.5625rem;
      height: 100vh;
      box-shadow: 0.063rem 0 1.25rem 0 #8690a3;
    
    }
    .sidenav-collapsed {
      width: 0;
    }
    .sidenav .logo-container {
      display: flex;
      align-items: center;
      padding: 0.938rem 0.938rem 0 0.938rem;
      width: 100%;
      background-color: #3d4862;
    }
    .sidenav .logo-container .logo {
      background: #fff;
      text-align: center;
      width: 3rem;
      min-width: 3rem;
      border-radius: 0.313rem;
      padding: 0.313rem;
      font-size: 24px;
      font-weight: 900;
      cursor: pointer;
      border: none;
    }
    .sidenav .logo-container .logo-text {
      margin-left: 1.5rem;
      font-size: 24px;
      font-weight: 700;
      color: #fff;
    }
    .sidenav .logo-container .btn-close {
 
      cursor: pointer;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background-color: transparent;
      border: none;
    }
    .sidenav .logo-container .btn-close .fa-times {
      color: #fff;
      font-size: 24px;
    }
    .sidenav .button.btn-close.btn-close-float-right {  
      float: left;
      background: red;
    }
    .sidenav-nav {
      background-image: url(./login15.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      list-style: none;
      padding: 0.938rem;
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: calc(100% - 3.65rem);
      cursor: pointer;
    }
    .sidenav-nav .sidenav-nav-item {
      width: 100%;
      margin-bottom: 0.625rem;
    }
    .sidenav-nav .sidenav-nav-item .sidenav-nav-link, .sidenav-nav .sidenav-nav-item .sidenav-nav-link-header {
      display: flex;
      align-items: center;
      height: 3rem;
      color: #f3f3f3;
      text-decoration: none;
      border-radius: 0.625rem;
      transition: all 0.3s ease;
      text-transform: capitalize;

    }

    .sidenav-nav .sidenav-nav-item .sidenav-nav-link-header  {
      text-transform: uppercase;
      margin-right: 15px;
      border-top: #606060 1px solid;
      border-radius: 0;
    }
    .sidenav-nav .sidenav-nav-item .sidenav-nav-link-header .sidenav-link-icon  {
      text-transform: uppercase;
      margin-right: 15px;;
    }
    .sidenav-nav .sidenav-nav-item .sidenav-nav-link .sidenav-link-icon {
      font-size: 22px;
      width: 2rem;
      min-width: 2rem;
      text-align: center;
      padding: 12px;
      margin-left: 15px;;
   
    }

    .sidenav-nav .sidenav-nav-item .sidenav-nav-link .sidenav-link-text {
      margin-left: 1.5rem;
    }
    .sidenav-nav .sidenav-nav-item:hover .sidenav-nav-link {
      background-color: #fff;
      color: #000;
    }
    
    .active {
      background-color: #fff;
    }
    .active .sidenav-link-icon,
    .active .sidenav-link-text {
      color: #000;
    }

    .p-panelmenu-content{
      background-color: transparent;
      border-color:transparent;
    }
    .p-panelmenu .p-panelmenu-header:not(.p-disabled).p-highlight .p-panelmenu-header-content{
      background-color: transparent;
      border-color:transparent;
    }
    .p-panelmenu-header-content{
      background-color: transparent;
      border-color:transparent;
    }

    .caret {
      display: inline-block;
      width: 0;
      height: 0;
      border-top: 8px dashed;
      border-top: 4px solid\9;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      /* margin-top: 13px; */
      position: absolute;
      right: 20px;
      transition: all .15s ease-in;
  }

  .caret.rotate-caret {
      transform: rotate(180deg);
  }