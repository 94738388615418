.date-selector-row{
      display: flex;
      flex-direction: row;;
      align-items: center;
      padding: 10px;
      border-bottom: 1px solid rgb(222, 220, 220);
      width: 100%;
}

.date-selector-row .title{
      font-weight: bold;
      padding: 10px;

}
.date-selector-row .selector .pi-check-square {
      background-color: blue;
      color: white;
      padding: 11px;
      border-radius: 25px;
}

.p-overlaypanel.p-component.p-ripple-disabled.p-overlaypanel-enter-done {
      margin: 20px;
}    