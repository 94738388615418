.box-label-alloy {
      width: 600px;
      height: 600px;
      background-color: white;;
      border: solid 1px gray;
      justify-content: center;
}
.box-label-alloy  .voided{
      position:absolute;
      right: 50%;
      top: 50%;
}
.box-label-alloy .left-description-bar{
      width: 33%;
      display: flex;
      flex-direction: column;
      height: 100%;
      position: relative;
      z-index: 3;
}
.box-label-alloy .left-description-bar img {
      margin-left: 5%;
      width: 100%;
      height: auto;
}
.box-label-alloy .left-description-bar .frame-profile {
      font-size: 2rem;
      font-weight: 600;
      padding-top: 20px;
      margin-left: 11px;
}
.box-label-alloy .left-description-bar .frame-style{
      font-size: 5rem;
      font-weight: 600;
      margin-left: 10px;
}
.box-label-alloy .right-image-bar {
      width: 66%;
      display: flex;
      flex-direction: column;
      height: 100%;
      position: relative;
}

.box-label-alloy .right-image-bar .qr-code {
      display: flex;
      position: absolute;
      top: 4px;
      left: 10px;
}

.box-label-alloy .right-image-bar .frame-image {
      display: flex;
      width: 100%;
      background-color:transparent;
}
.box-label-alloy .right-image-bar .frame-image img{
      display: flex;
      width: 92%;
      margin-left: auto;
      margin-right: 8px;
  
}

.box-label-alloy .right-image-bar .style-short-code {
      font-size: 11rem;
      position: absolute;
      right: 33px;
      bottom: -29px;
      font-weight: 600;
}

.box-label-alloy .right-image-bar .samsung2025 {
      font-size: 1.2rem;
      position: absolute;
      right: 33px;
      bottom: -41px;
      font-weight: 600;
}


.box-label-alloy .bottom-bar {


}
.box-label-alloy .bottom-bar .bottom-bar-description-left {
      display: flex;
      width: 50%;      
}

.box-label-alloy .bottom-bar .bottom-bar-description-left .bottom-bar-line {
      width: 97%;
      border-top: 3px solid #b5885c;
      align-self: center;
}

.box-label-alloy .bottom-bar .bottom-bar-size-right {
      display: flex;      
      width: 50%;
      justify-content: center;
      align-items: flex-end;      
}
.box-label-alloy .bottom-bar .bottom-bar-size-right .frame-size {
      display: flex;      
      color:white;
      padding: 6px 54px;
      font-size: 3rem;
      font-weight: 700;
      margin-right: 67px;
      margin-top: 17px;
}

.box-label-alloy .left-color-bar .qr-code svg {
      width: 100%;
      padding: 10px;
      height: fit-content;
}
.box-label-alloy .bottom-bar .bottom-bar-strip {
      display: flex;      
      font-size: 1.5rem;
      text-align: center;
      color: #AD7D4D;
}
